//
//
//
//
//
//
//
//
//
//
//

// cross tab communication
const sysend = require('sysend');
export default {
  data() {
    return {
      showInnerLoading: true,
      user_projects: [],
      thirdPartyCredentials: {},
    };
  },
  mounted() {
    const that = this;
    sysend.proxy('https://develop.evodynamics.io/');
    sysend.proxy('https://console-eu.evodynamics.io/');
    const routeQuery = this.$route.query;
    if (routeQuery.hasOwnProperty('code')) {
      sysend.broadcast('triggerGoogleProjectSelect', routeQuery);
      setTimeout(() => {
        that.showInnerLoading = false;
        window.close();
      }, 300);
    } else {
      this.$q.notify({
        progress: true,
        type: 'negative',
        closeBtn: true,
        message: 'Error',
        caption: 'Invalid Parameters from google login',
        timeout: 2000,
        position: 'top',
      });

      setTimeout(() => {
        this.$router.push({
          name: 'aiProjects',
        }).catch(() => {});
      }, 1000);
    }
  },
};
